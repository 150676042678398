import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchReceiveBookList(params) {
    return request({
        url: baseURL + '/receiveBook/list',
        method: 'GET',
        params: params
    })

}

export function fetchReceiveBookDetail(params) {
    return request({
        url: baseURL + '/receiveBook/detail',
        method: 'GET',
        params: params
    })
}

export function receiveBookSave(data) {
    return request({
        url: baseURL + '/receiveBook/save',
        method: 'POST',
        data
    })
}